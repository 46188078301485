
import { useEffect, useState } from 'react';

function Me(props) {
  

  return (
    <div className="Menu">
      About ME
    </div>
  );
}

export default Me;